import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Container, Navbar, Nav } from "react-bootstrap";
import { logout } from "../login/LoginActions";
import HomeForm from "./HomeForm"
import PersonForm from "./PersonForm"
import OrganizationForm from "./OrganizationForm"
import RoleForm from "./RoleForm"
import LocationForm from "./LocationForm"
import AdultEducationForm from "./AdultEducationForm"
import AssessmentDeliveryForm from "./AssessmentDeliveryForm"
import AssessmentDesignForm from "./AssessmentDesignForm"
import AssessmentItemResponseAndLearnerActionForm from "./AssessmentItemResponseAndLearnerActionForm"
import AssessmentResultsForm from "./AssessmentResultsForm"
import CalendarForm from "./CalendarForm"
import CompetenciesForm from "./CompetenciesForm"
import CourseSectionForm from "./CourseSectionForm"
import CourseSectionAttendanceForm from "./CourseSectionAttendanceForm"
import CredentialForm from "./CredentialForm"
import CareerAndTechnicalEducationForm from "./CareerAndTechnicalEducationForm"
import DailyAttendanceForm from "./DailyAttendanceForm"
import EarlyLearningChildEnrollmentForm from "./EarlyLearningChildEnrollmentForm"
import EarlyLearningClassGroupForm from "./EarlyLearningClassGroupForm"
import EarlyLearningOrganizationForm from "./EarlyLearningOrganizationForm"
import EarlyLearningProfessionalDevelopmentForm from "./EarlyLearningProfessionalDevelopmentForm"
import EarlyLearningStaffForm from "./EarlyLearningStaffForm"
import IdentityAuthenticationAndAuthorizationForm from "./IdentityAuthenticationAndAuthorizationForm"
import K12CourseSectionForm from "./K12CourseSectionForm"
import K12FinancialForm from "./K12FinancialForm"
import K12IncidentDisciplineForm from "./K12IncidentDisciplineForm"
import K12SchoolForm from "./K12SchoolForm"
import K12StudentEnrollmentForm from "./K12StudentEnrollmentForm"
import K12StudentForm from "./K12StudentForm"
import LearningResourcesForm from "./LearningResourcesForm"
import PersonProgramParticipationForm from "./PersonProgramParticipationForm"
import PostsecondaryInstitutionForm from "./PostsecondaryInstitutionForm"
import PostsecondaryOverviewForm from "./PostsecondaryOverviewForm"
import PostsecondaryStudentForm from "./PostsecondaryStudentForm"
import StaffForm from "./StaffForm"
import TeacherStudentDataLinkForm from "./TeacherStudentDataLinkForm"
import WorkforceForm from "./WorkforceForm"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Assuming you're using Font Awesome
import { faBars, faTimes, faHome } from "@fortawesome/free-solid-svg-icons"; // Assuming you want to use the bars icon

//import RoleForm from "../forms/RoleForm"; // Import RoleForm component
import "../../index.css"; // Import CSS file

class Dashboard extends Component {
  state = {
    activePanel: "Home", // State to track the active panel (Person, Organization, or Role),
    isMenuOpen: false, // State to track the mobile menu open/close,
    windowWidth: window.innerWidth // Track window width
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };


  onLogout = () => {
    this.props.logout();
  };

  // Function to handle click on panel links
  handlePanelClick = panel => {
    this.setState({
      activePanel: panel,
      isMenuOpen: false // Close the menu when a panel is clicked
    });
  };
  // Function to toggle mobile menu
  toggleMenu = () => {
    this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }));
  };
  closePanel = () => {
    this.setState({ isMenuOpen: false });
  };
  // Function to render form component based on the active panel
  renderFormComponent = () => {
    const { activePanel } = this.state;

    switch (activePanel) {
      case "Home":
        return <HomeForm />;
      case "Person":
        return <PersonForm />;
      case "Organization":
        return <OrganizationForm />;
      case "Role":
        return <RoleForm />;
      case "K12CourseSection":
        return <K12CourseSectionForm />;
      case "LearningResource":
        return <LearningResourcesForm />;
      case "Location":
        return <LocationForm />;
      case "AdultEducation":
        return <AdultEducationForm />;
      case "AssessmentDelivery":
        return <AssessmentDeliveryForm />;
      case "AssessmentDesign":
        return <AssessmentDesignForm />;
      case "AssessmentItemResponseAndLearnerAction":
        return <AssessmentItemResponseAndLearnerActionForm />;
      case "AssessmentResults":
        return <AssessmentResultsForm />;
      case "Calendar":
        return <CalendarForm />;
      case "Competencies":
        return <CompetenciesForm />;
      case "CourseSection":
        return <CourseSectionForm />;
      case "CourseSectionAttendance":
        return <CourseSectionAttendanceForm />;
      case "Credential":
        return <CredentialForm />;
      case "CareerAndTechnicalEducation":
        return <CareerAndTechnicalEducationForm />;
      case "DailyAttendance":
        return <DailyAttendanceForm />;
      case "EarlyLearningChildEnrollment":
        return <EarlyLearningChildEnrollmentForm />;
      case "EarlyLearningClassGroup":
        return <EarlyLearningClassGroupForm />;
      case "EarlyLearningOrganization":
        return <EarlyLearningOrganizationForm />;
      case "EarlyLearningProfessionalDevelopment":
        return <EarlyLearningProfessionalDevelopmentForm />;
      case "EarlyLearningStaff":
        return <EarlyLearningStaffForm />;
      case "IdentityAuthenticationAndAuthorization":
        return <IdentityAuthenticationAndAuthorizationForm />;
      case "K12CourseSection":
        return <K12CourseSectionForm />;
      case "K12Financial":
        return <K12FinancialForm />;
      case "K12IncidentDiscipline":
        return <K12IncidentDisciplineForm />;
      case "K12School":
        return <K12SchoolForm />;
      case "K12StudentEnrollment":
        return <K12StudentEnrollmentForm />;
      case "K12Student":
        return <K12StudentForm />;
      case "LearningResources":
        return <LearningResourcesForm />;
      case "PersonProgramParticipation":
        return <PersonProgramParticipationForm />;
      case "PostsecondaryInstitution":
        return <PostsecondaryInstitutionForm />;
      case "PostsecondaryOverview":
        return <PostsecondaryOverviewForm />;
      case "PostsecondaryStudent":
        return <PostsecondaryStudentForm />;
      case "Staff":
        return <StaffForm />;
      case "TeacherStudentDataLink":
        return <TeacherStudentDataLinkForm />;
      case "Workforce":
        return <WorkforceForm />;

      default:
        return null;
    }
  };

  render() {
    const { user } = this.props.auth;
    const { isMenuOpen, windowWidth } = this.state;
    const showIcon = windowWidth <= 820; // Conditionally show icon based on window width
    let activePanel = this.state.activePanel;

    return (
      <div>
        <Navbar bg="light" className="sticky-navbar" > {/* Add 'expand="md"' to enable responsive behavior */}
          <Navbar.Brand onClick={() => this.handlePanelClick("Home")} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faHome} />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Navbar.Text>
              User: <b>{user.username}</b>
            </Navbar.Text>
            &nbsp;&nbsp;
            <Nav.Link onClick={() => this.props.history.push('/report')}>Reports</Nav.Link>
            &nbsp;&nbsp;
            <Nav.Link onClick={this.onLogout}>Logout</Nav.Link>
          </Navbar.Collapse>
        </Navbar>
        <Container fluid className="container-dashboard">
          <div className={`left-panel ${isMenuOpen ? "open" : ""}`}>
            <h4>{'Schema'}</h4>
            <Nav className="flex-column">

              {showIcon && <FontAwesomeIcon icon={faTimes} onClick={this.closePanel} style={{ cursor: "pointer", padding: "10px" }} />}
              <Nav.Link onClick={() => this.handlePanelClick("Person")} className={activePanel === "Person" ? "bold" : ""}>
                Person
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("Organization")} className={activePanel === "Organization" ? "bold" : ""}>
                Organization
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("Role")} className={activePanel === "Role" ? "bold" : ""}>
                Role
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("Location")} className={activePanel === "Location" ? "bold" : ""}>
                Location
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("AdultEducation")} className={activePanel === "AdultEducation" ? "bold" : ""}>
                Adult Education
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("AssessmentDelivery")} className={activePanel === "AssessmentDelivery" ? "bold" : ""}>
                Assessment Delivery
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("AssessmentDesign")} className={activePanel === "AssessmentDesign" ? "bold" : ""}>
                Assessment Design
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("AssessmentItemResponseAndLearnerAction")} className={activePanel === "AssessmentItemResponseAndLearnerAction" ? "bold" : ""}>
                Assessment Item Response and Learner Action
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("AssessmentResults")} className={activePanel === "AssessmentResults" ? "bold" : ""}>
                Assessment Results
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("Calendar")} className={activePanel === "Calendar" ? "bold" : ""}>
                Calendar
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("Competencies")} className={activePanel === "Competencies" ? "bold" : ""}>
                Competencies
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("CourseSection")} className={activePanel === "CourseSection" ? "bold" : ""}>
                Course Section
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("CourseSectionAttendance")} className={activePanel === "CourseSectionAttendance" ? "bold" : ""}>
                Course Section Attendance
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("Credential")} className={activePanel === "Credential" ? "bold" : ""}>
                Credentials
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("CareerAndTechnicalEducation")} className={activePanel === "CareerAndTechnicalEducation" ? "bold" : ""}>
                Career and Technical Education
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("DailyAttendance")} className={activePanel === "DailyAttendance" ? "bold" : ""}>
                Daily Attendance
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("EarlyLearningChildEnrollment")} className={activePanel === "EarlyLearningChildEnrollment" ? "bold" : ""}>
                Early Learning: Child Enrollment
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("EarlyLearningClassGroup")} className={activePanel === "EarlyLearningClassGroup" ? "bold" : ""}>
                Early Learning: Class Group
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("EarlyLearningOrganization")} className={activePanel === "EarlyLearningOrganization" ? "bold" : ""}>
                Early Learning: Organization
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("EarlyLearningProfessionalDevelopment")} className={activePanel === "EarlyLearningProfessionalDevelopment" ? "bold" : ""}>
                Early Learning: Professional Development
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("EarlyLearningStaff")} className={activePanel === "EarlyLearningStaff" ? "bold" : ""}>
                Early Learning: Staff
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("IdentityAuthenticationAndAuthorization")} className={activePanel === "IdentityAuthenticationAndAuthorization" ? "bold" : ""}>
                Identity Authentication and Authorization
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("K12CourseSection")} className={activePanel === "K12CourseSection" ? "bold" : ""}>
                K12: Course Section
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("K12Financial")} className={activePanel === "K12Financial" ? "bold" : ""}>
                K12: Financial
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("K12IncidentDiscipline")} className={activePanel === "K12IncidentDiscipline" ? "bold" : ""}>
                K12: Incident and Discipline
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("K12School")} className={activePanel === "K12School" ? "bold" : ""}>
                K12: School
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("K12StudentEnrollment")} className={activePanel === "K12StudentEnrollment" ? "bold" : ""}>
                K12: Student Enrollment
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("K12Student")} className={activePanel === "K12Student" ? "bold" : ""}>
                K12: Student
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("LearningResources")} className={activePanel === "LearningResources" ? "bold" : ""}>
                Learning Resources
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("PersonProgramParticipation")} className={activePanel === "PersonProgramParticipation" ? "bold" : ""}>
                Person Program Participation
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("PostsecondaryInstitution")} className={activePanel === "PostsecondaryInstitution" ? "bold" : ""}>
                Postsecondary: Institution
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("PostsecondaryOverview")} className={activePanel === "PostsecondaryOverview" ? "bold" : ""}>
                Postsecondary: Overview
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("PostsecondaryStudent")} className={activePanel === "PostsecondaryStudent" ? "bold" : ""}>
                Postsecondary: Student
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("Staff")} className={activePanel === "Staff" ? "bold" : ""}>
                Staff
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("TeacherStudentDataLink")} className={activePanel === "TeacherStudentDataLink" ? "bold" : ""}>
                Teacher-Student Data Link
              </Nav.Link>
              <Nav.Link onClick={() => this.handlePanelClick("Workforce")} className={activePanel === "Workforce" ? "bold" : ""}>
                Workforce
              </Nav.Link>

            </Nav>
          </div>
          <div className="right-panel">
            {showIcon && <FontAwesomeIcon icon={faBars} onClick={this.toggleMenu} style={{ cursor: "pointer", marginLeft: "10px" }} />}
            {this.renderFormComponent()} {/* Render the form component */}
          </div>
        </Container>
      </div>
    );
  }
}

Dashboard.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  logout
})(withRouter(Dashboard));
