import React, { Component } from "react";
import { Table } from "react-super-responsive-table";
import axiosInstance from "../../hooks/axiosMiddleware";
import "../../index.css";

class HomeForm extends Component {
  state = {
    fields: {},
    status: "",
    transactions: [],
    // Search criteria
    searchStartDate: "",
    searchEndDate: "",
    searchOrganization: "",
    searchEmail: "",
    searchSchema: "",
    // Pagination
    currentPage: 1,
    itemsPerPage: 10,
  };

  fetchData = async () => {
    try {
      // Fetch transactions
      const transactionResponse = await axiosInstance.get('/api/transactions', {
        params: {
          'pagination[pageSize]': 99999999
        }
      });
      console.log('Transaction API response:', transactionResponse.data);

      // Fetch users
      const userResponse = await axiosInstance.get('/api/users');
      console.log('User API response:', userResponse.data);

      // Create a map of users keyed by their id for quick lookup.
      const userMap = userResponse.data.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});

      // Merge user info into each transaction.
      if (
        transactionResponse.data &&
        transactionResponse.data.data &&
        transactionResponse.data.data.length > 0
      ) {
        const modifiedTransactions = transactionResponse.data.data.map(tx => {
          const txUid = tx.attributes.uid;
          const user = userMap[txUid] || null;
          return {
            ...tx,
            attributes: {
              ...tx.attributes,
              user, // attach the matching user details
            }
          };
        });

        // Update state with the merged transactions.
        this.setState({ transactions: modifiedTransactions });
      } else {
        this.setState({ transactions: [] });
      }
    } catch (error) {
      console.error('Error fetching data from API:', error);
    }
  };

  componentDidMount() {
    this.setState({ fields: {} });
    this.fetchData();
  }

  // Handle search input changes
  handleInputChange = (e) => {
    const { name, value } = e.target;
    // Reset to page 1 on new search input.
    this.setState({ [name]: value, currentPage: 1 });
  };

  // Clear all search filters
  clearFilters = () => {
    this.setState({
      searchStartDate: "",
      searchEndDate: "",
      searchOrganization: "",
      searchEmail: "",
      searchSchema: "",
      currentPage: 1,
    });
  };

  // Returns the filtered transactions based on the search criteria.
  getFilteredTransactions = () => {
    const { transactions, searchStartDate, searchEndDate, searchOrganization, searchEmail, searchSchema } = this.state;
    return transactions.filter(tx => {
      const { updatedAt, user, type } = tx.attributes;
      let matches = true;

      // Filter by date range if specified.
      if (searchStartDate) {
        matches = matches && new Date(updatedAt) >= new Date(searchStartDate);
      }
      if (searchEndDate) {
        matches = matches && new Date(updatedAt) <= new Date(searchEndDate);
      }
      // Filter by Organization (free text, case insensitive)
      if (searchOrganization) {
        matches =
          matches &&
          user &&
          user.organization &&
          user.organization.toLowerCase().includes(searchOrganization.toLowerCase());
      }
      // Filter by Email (free text, case insensitive)
      if (searchEmail) {
        matches =
          matches &&
          user &&
          user.email &&
          user.email.toLowerCase().includes(searchEmail.toLowerCase());
      }
      // Filter by Schema Type (free text, case insensitive)
      if (searchSchema) {
        matches = matches && type && type.toLowerCase().includes(searchSchema.toLowerCase());
      }
      return matches;
    });
  };

  // Change page in pagination.
  handlePageChange = (direction) => {
    const { currentPage } = this.state;
    if (direction === 'prev' && currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    } else if (direction === 'next') {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  // Download CSV of the currently filtered transactions.
  downloadCSV = () => {
    const filtered = this.getFilteredTransactions();
    const csvHeader = ['Last Update', 'Organization', 'Email', 'Schema Type'];
    const csvRows = filtered.map(tx => {
      const { updatedAt, user, type } = tx.attributes;
      return [
        updatedAt,
        user ? user.organization : "N/A",
        user ? user.email : "N/A",
        type
      ].join(",");
    });
    const csvString = [csvHeader.join(","), ...csvRows].join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "transactions.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  render() {
    const { currentPage, itemsPerPage, searchStartDate, searchEndDate, searchOrganization, searchEmail, searchSchema } = this.state;
    const filteredTransactions = this.getFilteredTransactions();

    // Calculate pagination
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;
    const currentTransactions = filteredTransactions.slice(indexOfFirst, indexOfLast);
    const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);

    return (
      <div className="person-form-container">
        <div className="data-entry">
          <h4>Transaction List</h4>
          <div
            className="search-filters"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              alignItems: "flex-end",
              marginBottom: "1rem",
              padding: "1rem",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="searchStartDate">Start Date:</label>
              <input
                type="date"
                id="searchStartDate"
                name="searchStartDate"
                value={searchStartDate}
                onChange={this.handleInputChange}
                className="form-control"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="searchEndDate">End Date:</label>
              <input
                type="date"
                id="searchEndDate"
                name="searchEndDate"
                value={searchEndDate}
                onChange={this.handleInputChange}
                className="form-control"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="searchOrganization">Organization:</label>
              <input
                type="text"
                id="searchOrganization"
                name="searchOrganization"
                value={searchOrganization}
                onChange={this.handleInputChange}
                placeholder="Enter organization"
                className="form-control"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="searchEmail">Email:</label>
              <input
                type="text"
                id="searchEmail"
                name="searchEmail"
                value={searchEmail}
                onChange={this.handleInputChange}
                placeholder="Enter email"
                className="form-control"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="searchSchema">Schema Type:</label>
              <input
                type="text"
                id="searchSchema"
                name="searchSchema"
                value={searchSchema}
                onChange={this.handleInputChange}
                placeholder="Enter schema type"
                className="form-control"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <button onClick={this.downloadCSV} className="btn btn-primary">
                Download CSV
              </button>
              <button onClick={this.clearFilters} className="btn btn-secondary">
                Clear Filters
              </button>
            </div>
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Last Update</th>
              <th>Organization</th>
              <th>Email</th>
              <th>Schema Type</th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions.length > 0 ? (
              currentTransactions.map(tx => (
                <tr key={tx.id}>
                  <td>{tx.attributes.updatedAt}</td>
                  <td>{tx.attributes.user?.organization || "N/A"}</td>
                  <td>{tx.attributes.user?.email || "N/A"}</td>
                  <td>{tx.attributes.type}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No transactions found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div
          className="pagination"
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <button
            onClick={() => this.handlePageChange("prev")}
            disabled={currentPage === 1}
            className="btn btn-outline-primary"
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => this.handlePageChange("next")}
            disabled={currentPage === totalPages || totalPages === 0}
            className="btn btn-outline-primary"
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

export default HomeForm;
