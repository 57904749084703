import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Container, Navbar, Nav } from "react-bootstrap";
import { logout } from "../login/LoginActions";
import HomeForm from "./HomeForm"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Assuming you're using Font Awesome
import { faBars, faTimes, faHome } from "@fortawesome/free-solid-svg-icons"; // Assuming you want to use the bars icon

//import RoleForm from "../forms/RoleForm"; // Import RoleForm component
import "../../index.css"; // Import CSS file

class Report extends Component {
  state = {
    activePanel: "Home", // State to track the active panel (Person, Organization, or Role),
    isMenuOpen: false, // State to track the mobile menu open/close,
    windowWidth: window.innerWidth // Track window width
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };


  onLogout = () => {
    this.props.logout();
  };

  // Function to handle click on panel links
  handlePanelClick = panel => {
    if (panel === "Dashboard") {
      this.props.history.push('/dashboard');
      return;
    }
    this.setState({
      activePanel: panel,
      isMenuOpen: false // Close the menu when a panel is clicked
    });
  };

  // Function to toggle mobile menu
  toggleMenu = () => {
    this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }));
  };
  closePanel = () => {
    this.setState({ isMenuOpen: false });
  };
  // Function to render form component based on the active panel
  renderFormComponent = () => {
    const { activePanel } = this.state;

    switch (activePanel) {
      case "Home":
        return <HomeForm />;


      default:
        return null;
    }
  };

  render() {
    const { user } = this.props.auth;
    const { isMenuOpen, windowWidth } = this.state;
    const showIcon = windowWidth <= 820; // Conditionally show icon based on window width
    let activePanel = this.state.activePanel;

    return (
      <div>
        <Navbar bg="light" className="sticky-navbar" > {/* Add 'expand="md"' to enable responsive behavior */}
          <Navbar.Brand onClick={() => this.handlePanelClick("Dashboard")} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faHome} />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Navbar.Text>
              User: <b>{user.username}</b>
            </Navbar.Text>
            &nbsp;&nbsp;
            <Nav.Link onClick={() => this.props.history.push('/report')}>Reports</Nav.Link>
            &nbsp;&nbsp;
            <Nav.Link onClick={this.onLogout}>Logout</Nav.Link>
          </Navbar.Collapse>
        </Navbar>
        <Container fluid className="container-dashboard">
          <div className={`left-panel ${isMenuOpen ? "open" : ""}`}>
            <h4>{'Reports'}</h4>
            <Nav className="flex-column">

              {showIcon && <FontAwesomeIcon icon={faTimes} onClick={this.closePanel} style={{ cursor: "pointer", padding: "10px" }} />}
              <Nav.Link onClick={() => this.handlePanelClick("Home")} className={activePanel === "Home" ? "bold" : ""}>
                Transaction Report
              </Nav.Link>

            </Nav>
          </div>
          <div className="right-panel">
            {showIcon && <FontAwesomeIcon icon={faBars} onClick={this.toggleMenu} style={{ cursor: "pointer", marginLeft: "10px" }} />}
            {this.renderFormComponent()} {/* Render the form component */}
          </div>
        </Container>
      </div>
    );
  }
}

Report.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  logout
})(withRouter(Report));
